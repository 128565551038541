import { useEffect } from "react";
import { useRouter } from "next/router";
import * as ga from "../lib/analytics";
import * as atatus from "atatus-spa";
import { sendAppierEvent } from "lib/appier";

export default function TrackingHandler() {
  const router = useRouter();

  // Atatus
  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.NEXT_PUBLIC_ATATUS_URL;
    script.async = true; // 確保腳本異步加載
    script.onload = () => {
      if (window.atatus) {
        atatus.config(process.env.NEXT_PUBLIC_ATATUS_ID).install();
      }
    };
    // 將腳本元素添加到文檔頭部
    document.head.appendChild(script);
    // 在組件卸載時移除腳本
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Google Analytics
  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  // Appier 查看服務（與 UGO 相同邏輯）
  useEffect(() => {
    if (!window) return
    if (window.location.pathname.includes("callback")) return

    const _appier_product_viewed_date = window.localStorage.getItem("_appier_product_viewed_date")

    if (_appier_product_viewed_date) {
      const _appier_product_viewed_date_time = parseInt(_appier_product_viewed_date)
      const _appier_product_viewed_date_now = new Date().getTime()

      if (_appier_product_viewed_date_now - _appier_product_viewed_date_time < 3600000) {
        return
      }
    }

    sendAppierEvent("service_viewed", {
      service_name: "租車",
      service_id: "UD_TW",
      service_url: window.location.pathname,
    })

    // 設定localStorage _appier_product_viewed_date
    // 一小時內 | 用戶持續瀏覽 => 都不會再發送一次
    window.localStorage.setItem("_appier_product_viewed_date", new Date().getTime())
  }, []);
  return null;
}
